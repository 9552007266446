import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <div class="min-h-screen">
      <header class="bg-[#FFC629] py-10 h-auto items-center justify-center">
        <div class="container mx-auto text-black text-center p-10">
          <h1 className="text-5xl font-semibold mb-5">
            JustLinked Technologies Limited
          </h1>
          <h1 className="text-4xl font-semibold mb-5">Terms of Service</h1>
          <h1 className="text-2xl font-semibold">
            Effective: December 1, 2023
          </h1>
        </div>
      </header>

      <div className="p-10 bg-black text-white">
        <div style={{ fontFamily: "linkedmedium" }} className="mt-5 text-white">
          <p className="mb-5">
            Welcome!
            <br />
            <br />
            We’ve drafted these Terms of Service (which we call the “Terms”), so
            you’ll know the rules that govern our relationship with you as a
            user of our Services. Although we have tried our best to strip the
            legalese from the Terms, there are places where they may still read
            like a traditional contract. There’s a good reason for that: these
            Terms form a legally binding contract between you and JustLinked
            Technologies Ltd. (“Linked”). So please read them carefully.
          </p>
          <p className="mb-5">
            By using Linked or any of our other products or services that are
            subject to these Terms (which we refer to collectively as the
            “Services”), you agree to the Terms. If so, Linked grants you a
            non-assignable, nonexclusive, revocable, and non-sublicensable
            licence to use the Services in accordance with these Terms and our
            policies. Of course, if you don’t agree with the Terms, then don’t
            use the Services.
          </p>
          <p>
            <b>ARBITRATION NOTICE:</b> THESE TERMS CONTAIN AN ARBITRATION CLAUSE
            A LITTLE LATER ON.
            <br />
            <br />
            YOU AND LINKED AGREE THAT, EXCEPT FOR CERTAIN TYPES OF DISPUTES
            MENTIONED IN THAT ARBITRATION CLAUSE, DISPUTES BETWEEN US WILL BE
            RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND LINKED WAIVE
            ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE
            ARBITRATION.
            <br />
            <br />
            YOU HAVE THE RIGHT TO OPT OUT OF ARBITRATION AS EXPLAINED IN THAT
            ARBITRATION CLAUSE.
          </p>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">1. Who Can Use the Services</h1>
            <p style={{ fontFamily: "linkedmedium" }}>
              Our services are not directed to individuals under the age of 18,
              and you must confirm that you are 18 years or older to create an
              account and use the Services. If we have actual knowledge that you
              are under the age of 18 (or that minimum age at which a person may
              use the Services in your state, province or country without
              parental consent, if greater), we will cease providing Service to
              you and delete your account and your data. So please read all such
              terms carefully. By using the Services, you represent, warrant,
              and agree that:
            </p>
            <div className="ml-5 mt-3">
              <p style={{ fontFamily: "linkedmedium" }} className="mb-3">
                ⦁ You are forming a binding contract with Linked;
                <br />
                <br />
                ⦁ You are not a person who is barred from using the Services
                under the laws of the Federal Republic of Nigeria or any other
                applicable jurisdiction;
                <br />
                <br />
                ⦁ You will comply with these Terms (including any other terms
                and policies referenced in these Terms, such as the Community
                Guidelines) and all applicable local, state, national and
                international laws, rules and regulations.
                <br />
                <br />⦁ If you are using the Services on behalf of a business or
                some other entity, you represent that you are authorized to bind
                that business or entity to these Terms and you agree to these
                Terms on behalf of that business or entity (and all references
                to “you” and “your” in these Terms will mean both you as the end
                user and that business or entity).
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">2. Rights You Grant us</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Many of our Services let you create, upload, post, send, receive
              and store content. When you do that, you retain whatever ownership
              rights in that content you had to begin with. But you grant us a
              licence to use that content. How broad that licence is depends on
              which Services you use and the settings you have selected.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              For all content you submit to the Services (including Public
              Content), you grant Linked and our affiliates a worldwide,
              royalty-free, sub-licensable and transferable licence to host,
              store, cache, use, display, reproduce, modify, adapt, edit,
              publish, analyse, transmit and distribute that content. This
              licence is for the purpose of operating, developing, providing,
              promoting, and improving the Services and researching and
              developing new ones. This licence includes a right for us to make
              your content available to, and pass these rights along to, service
              providers with whom we have contractual relationships related to
              the provision of the Services, solely for the purpose of providing
              such Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              This means, among other things, that you will not be entitled to
              any compensation if your content, videos, photos, sound
              recordings, musical compositions, name, image, likeness or voice
              are used by us, our affiliates, users of the Services or our
              business partners. For information about how to tailor who can
              watch your content, please take a look at our Privacy Policy and
              Support Site. All Public Content must be appropriate for people
              ages 18+.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              While we're not required to do so, we may access, review, screen
              and delete your content at any time and for any reason, including
              to provide and develop the Services or if we think your content
              violates these Terms or any applicable laws. You alone, though,
              remain responsible for the content you create, upload, post, send
              or store through the Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We, our affiliates, and our third-party partners may place
              advertising on the Services, including personalised advertising
              based on the information you provide us, we collect, or we obtain
              about you. Advertising may sometimes appear near, between, over,
              or in your content.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We always love to hear from our users. But if you provide feedback
              or suggestions, just know that we can use them without
              compensating you and without any restriction or obligation to you.
              You agree that we will own all rights in anything we develop based
              on such feedback or suggestions.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> If you post content that you own to the
              Services, you remain the owner, but you allow us and others to use
              it to provide and promote our Services. You also allow other users
              to view and, in some cases, use any content you make available to
              others on the Services. We have various rights to change and
              remove your content, but you always remain responsible for
              everything you create, post or share.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">3. Privacy</h1>
            <p style={{ fontFamily: "linkedmedium" }}>
              Your privacy matters to us. You can learn how your information is
              handled when you use our Services by reading our{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/privacy"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">4. Content Moderation</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Almost all the content on our Services is produced by users,
              publishers and other third parties. Whether that content is posted
              publicly or sent privately, the content is the sole responsibility
              of the user or entity that submitted it. Although Linked reserves
              the right to review, moderate or remove all content that appears
              on the Services, we do not review all of it. So, we cannot - and
              do not - guarantee that other users or the content they provide
              through the Services will comply with our Terms and Community
              Guidelines.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Users can report accounts, or content produced by other accounts
              for violation of our Terms, Community Guidelines or other
              guidelines and policies. More information about how to report
              content and accounts is available on our Support Site.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We hope you’ll understand any decisions we make about content or
              user accounts, but if you have any complaints or concerns, you can
              use available in-app options, or{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                contact us
              </a>{" "}
              via our official website.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Upon receiving a complaint, we will:
            </p>
            <div
              style={{ fontFamily: "linkedmedium" }}
              className="ml-5 mt-3 mb-5"
            >
              <ul>
                <li>
                  ⦁ Ensure the complaint is reviewed in a timely,
                  non-discriminatory, diligent and non-arbitrary manner;
                </li>
                <br />
                <li>
                  ⦁ Reverse our decision if we determine our initial assessment
                  was incorrect; and
                </li>
                <br />
                <li>
                  ⦁ Inform you of our decision and of any possibilities for
                  re-dress promptly.
                </li>
              </ul>
            </div>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Most content on the Services is owned or
              controlled by others, and we don't have any control or
              responsibility over that content. We have content moderation
              policies and processes in place that apply to content on the
              Services.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">
              5. Respecting the Services and Linked’s Rights
            </h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              As between you and us, Linked is the owner of the Services, which
              includes all related brands, works of authorship, software and
              other proprietary content, features and technology.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              You must also respect Linked 's rights and adhere to the Linked
              Brand Guidelines and any other guidelines, support pages or FAQs
              published by Linked or our affiliates. That means, among other
              things, you may not do, attempt to do, enable or encourage anyone
              else to do, any of the following; and doing so may result in us
              terminating or suspending your access to the Services:
            </p>
            <div
              style={{ fontFamily: "linkedmedium" }}
              className="ml-5 mt-3 mb-5"
            >
              <p className="mb-3">
                ⦁ Use branding, logos, icons, user interface elements, product
                or brand look and feel, designs, photographs, videos or any
                other materials Linked makes available via the Services, except
                as explicitly allowed by these Terms, the Linked Brand
                Guidelines or any other guidelines published by Linked or our
                affiliates;
              </p>
              <p className="mb-3">
                ⦁ Violate or infringe Linked's, our affiliates', or any other
                third-party's copyrights, trademarks or other intellectual
                property rights, including by using the Services to submit,
                display, post, create or generate any infringing content;
              </p>
              <p className="mb-3">
                ⦁ Copy, modify, archive, download, upload, disclose, distribute,
                sell, lease, syndicate, broadcast, perform, display, make
                available, make derivatives of, or otherwise use the Services or
                the content on the Services, other than temporary files that are
                automatically cached by your web browser for display purposes,
                as otherwise expressly permitted in these Terms, as otherwise
                expressly permitted by us in writing, or as enabled by the
                Service’s intended functionality;
              </p>
              <p className="mb-3">
                ⦁ Create another account if we have already disabled your
                account, attempt to access the Services through unauthorized
                third-party applications, solicit login credentials from other
                users, or buy, sell or lease access to your account, a username,
                or a friend's link;
              </p>
              <p className="mb-3">
                ⦁ Use any robot, spider, crawler, scraper or other automated
                means, interface or script to access the Services or extract
                other users’ information;
              </p>
              <p className="mb-3">
                ⦁ Use or develop any third-party applications that interact with
                the Services or other users’ content or information without our
                written consent;
              </p>
              <p className="mb-3">
                ⦁ Use the Services in any manner that could interfere with,
                disrupt, negatively affect or inhibit other users from fully
                enjoying the Services, or that could damage, disable,
                overburden, or impair the functioning of the Services in any
                manner;
              </p>
              <p className="mb-3">
                ⦁ Attempt to circumvent any content-filtering techniques we
                employ or attempt to access areas or features of the Services
                that you are not authorized to access;
              </p>
              <p className="mb-3">
                ⦁ Upload viruses or other malicious code or otherwise
                compromise, bypass, or circumvent the security of the Services;
              </p>
              <p className="mb-3">
                ⦁ Probe, scan, or test the vulnerability of our Services or any
                system or network;
              </p>
              <p className="mb-3">
                ⦁ Reverse engineer, duplicate, decompile, disassemble, or decode
                the Services (including any underlying idea or algorithm), or
                otherwise extract the source code of the software of the
                Service;
              </p>
              <p className="mb-3">
                ⦁ Violate any applicable law or regulation in connection with
                your access to or use of the Services; or
              </p>
              <p className="mb-3">
                ⦁ Access or use the Services in any way not expressly permitted
                by these Terms or our Community Guidelines.
              </p>
            </div>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> We own or control all of the content, features
              and functionalities of the Services. To ensure the Services and
              other users are protected from harm, there are rules we need you
              to follow when you use our Services. Failure to comply with these
              rules may result in suspension or termination of your account.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">6. Respecting Others' Rights</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Linked respects the rights of others. And so should you. You
              therefore may not use the Services, or enable anyone else to use
              the Services, in a manner that violates or infringes someone
              else’s rights of publicity, privacy, copyright, trademark, or
              other intellectual property right. When you submit content to the
              Services, you agree and represent that you own that content, or
              that you have received all necessary permissions, clearances, and
              authorizations in order to submit it to the Services. You also
              agree that you will not use or attempt to use another user's
              account except as permitted by Linked or its affiliates.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Linked honours copyright laws and takes reasonable steps to
              quickly remove from our Services any infringing material that we
              become aware of. If Linked becomes aware that a user has
              repeatedly infringed copyrights, we will take reasonable steps
              within our power to suspend or terminate the user's account. If
              you believe that anything on the Services infringes a copyright
              that you own or control, please report it using the in-app options
              or{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                contact us
              </a>{" "}
              via our official website.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Make sure you own or have the right to use any
              content you make available on the Services. If you use content
              owned by someone else without permission, we may terminate your
              account. If you see anything which you believe infringes your
              intellectual property rights, let us know.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">7. Safety</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We try hard to ensure the safety of all users using our Services.
              But we can’t guarantee it. That’s where you come in. By using the
              Services, you agree that you will at all times comply with these
              Terms, including our Community Guidelines and any other policy
              Linked makes available in order to maintain the safety of the
              Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              If you fail to comply, we reserve the right to remove any
              offending content; terminate or limit the visibility of your
              account, and retain data relating to your account in accordance
              with our data retention policies; and notify third parties -
              including law enforcement - and provide those third-parties with
              information relating to your account. This step may be necessary
              to protect the safety of our users, and others, to investigate,
              remedy, and enforce potential Terms violations, and to detect and
              resolve any fraud or security concerns.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We also care about your physical safety while using our Services.
              So do not use our Services in a way that would distract you from
              obeying traffic or safety laws. For example, never use the
              Services while driving. And never put yourself or others in harm's
              way just to create a Link or to engage with other Linked features.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> We try to make our Services as safe as
              possible, but we need your help. These Terms, our Community
              Guidelines, and other Linked policies contain important
              information about how to keep the Services and other users safe.
              And never put yourself or others in harm's way when using our
              Services.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">8. Your Account</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              To use our Services, you need to create an account. You agree to
              provide us with accurate, complete, and updated information for
              your account. You are responsible for any activity that occurs in
              your account. So it’s important that you keep your account secure.
              One way to help keep your account secure is to select a strong
              password that you don’t use for any other account and to enable
              two-factor authentication. If you think that someone has gained
              access to your account, please{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                contact us
              </a>{" "}
              immediately.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Any software that we provide to you may automatically download and
              install upgrades, updates, or other new features. You may be able
              to adjust these automatic downloads through your device’s
              settings. You agree not to create any account if we have
              previously removed or banned you or your account from any of our
              Services, unless we consent otherwise.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Keep your account details safe and secure. Only
              use an account if you are authorized by us to do so.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">9. Data Charges and Mobile Phones</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              You are responsible for any mobile charges that you may incur for
              using our Services. This includes data charges and charges for
              messaging, such as SMS. MMS or other messaging protocols or
              technologies (collectively, "Messages"). If you’re unsure what
              those charges may be, you should ask your service provider before
              using the Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              By providing us with your mobile phone number you agree, among
              other things, to receive Messages from Linked related to the
              Services, such as one-time-password (OTP) or about promotions
              (where we have consent or as permitted by law). You agree that
              these Messages may be received even if your mobile phone number is
              registered on any state or federal Do Not Call list, or
              international equivalent.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              If you change or deactivate the mobile phone number that you used
              to create an account, you must update your account information
              through the Settings Page in-app as soon as possible to prevent us
              from sending to someone else messages intended for you.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> We may send you Messages, and mobile charges -
              which you are responsible for, may apply when you use our
              Services.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">
              10. Third-Party Materials and Services
            </h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Certain Services may display, include or make available content,
              data, information applications, features or materials from
              third-parties ("Third-Party Materials"), provide links to certain
              third-party websites or permit the use of third-party services in
              connection with those Services. If you use any Third-Party
              Materials or third-party services made available through or in
              connection with our Services (including Services we jointly offer
              with third-party), the applicable third-party's terms will govern
              their relationship with you. Neither Linked nor any of our
              affiliates is responsible or liable for a third-party's terms or
              actions taken under any third-party's terms.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Furthermore, by using the Services, you acknowledge and agree that
              Linked is not responsible for examining or evaluating the content,
              accuracy, completeness, availability, timeliness, validity,
              copyright compliance, legality, decency, quality, or any other
              aspect of such Third-Party Materials or third-party services
              websites. We do not warrant or endorse and do not assume and will
              not have any liability or responsibility to you or any other
              person for any third-party services, Third-Party Materials or
              thirdparty websites or for any other materials, products or
              services of third-parties. Third-Party Materials, the availability
              of third-party services and links to other websites are provided
              solely as a convenience to you.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Linked isn't responsible for third-party
              features, content or services accessible via or in connection with
              our Services - please make sure you read the third-party's terms.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">
              11. Modifying the Services and These Terms
            </h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We’re relentlessly improving our Services and creating new ones
              all the time. That means we may add or remove features, products
              or functionalities, and we may also suspend or stop the Services
              altogether. We may take any of these actions at any time, and when
              we do, we may not provide you with any notice beforehand.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              This also means we may need to update these Terms to reflect any
              changes to our Services or how we provide them, as well as to
              comply with legal requirements, or for other legal or security
              reasons. If those changes to these Terms are material, we will
              provide you with reasonable advance notice (unless changes are
              required sooner, for example, as a result of a change in legal
              requirements or where we are launching new Services or features).
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We will also update the date at the top of these Terms, which
              reflect the effective date of such Terms. Your continued access or
              use of the Services after the date of the new Terms constitutes
              your acceptance of the new Terms. If you do not agree to the new
              Terms, you must stop accessing or using the Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Our Services are going to evolve over time. We
              may update these Terms from time to time to reflect these changes
              or for other reasons.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">12. Termination and Suspension</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We may restrict, terminate or temporarily suspend your access to
              the Services if you fail to comply with these Terms, our Community
              Guidelines or the law, for reasons outside of our control, or for
              any other reason. That means that we may terminate these Terms,
              stop providing you with all or any part of the Services, or impose
              new or additional limits on your ability to use our Services. For
              example, we may deactivate your account due to prolonged
              inactivity, and we may reclaim your username at any time for any
              reason. And while we'll try to give you reasonable notice
              beforehand, we can't guarantee that notice will be possible in all
              circumstances.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Where we restrict, terminate or suspend your access to the
              Services for violation of our Community Guidelines, we will notify
              you and provide an opportunity for you to appeal. Before we
              restrict, terminate or suspend your access to the Services, we
              will take into account all relevant facts and circumstances
              apparent from the information available to us, depending on the
              underlying reason for taking that action. For example, if you
              violate our Community Guidelines we consider the severity,
              frequency and impact of the violations as well as the intention
              behind the violation. This will inform our decision whether to
              restrict, terminate or suspend your access to the Services and, in
              the event of suspension, how long we suspend your access.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              While we hope you remain a lifelong Link, you can terminate these
              Terms at any time if you do not agree with any changes we make to
              these Terms, or for any other reason, by deleting your Linked
              account. Alternatively, you could decide to take a break by
              deactivating your account. In any of the aforementioned cases, you
              will need to{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                contact us
              </a>{" "}
              to assist you with the process.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> You can stop using the Services or delete your
              account at any time and for any reason, including if you don't
              like any changes to these Terms. We can restrict or terminate your
              access to the Services for the reasons set out above. When we do,
              we'll provide you notice in most cases, as well as an opportunity
              to appeal the decision.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">13. Indemnity</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              You agree, to the extent permitted by law, to indemnify, defend
              and hold harmless Linked, our affiliates, directors, officers,
              shareholders, employees, licensors and agents from and against any
              and all complaints, charges, claims, damages, losses, costs,
              liabilities, and expenses (including attorneys' fees) due to,
              arising out of, or relating in any way to:
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="ml-5 mb-5">
              (i) your access to or use of the Services or any product or
              services provided by a third party in connection with the
              Services, even if recommended, made available, or approved by
              Linked;
              <br />
              (ii) your content, including infringement claims related to your
              content;
              <br />
              (iii) your breach of these Terms or any applicable law or
              regulation; or
              <br />
              (iv) your negligence or wilful misconduct.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> If you cause us some damage, you will
              compensate us.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">14. Disclaimers</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              We try to keep the Services up and running and free of annoyances.
              But we make no promises that we will succeed.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND TO THE
              EXTENT PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE AND NONINFRINGEMENT. IN ADDITION, WHILE WE ATTEMPT TO
              PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT
              THAT:
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="ml-5 mb-5">
              (I) THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY;
              <br />
              (II) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS,
              DISRUPTIONS, OR IMPERFECTIONS; OR
              <br />
              (III) THAT ANY CONTENT, USER CONTENT OR INFORMATION YOU OBTAIN OR
              THROUGH THE SERVICES WILL BE TIMELY OR ACCURATE.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              NEITHER WE NOR OUR AFFILIATES TAKE RESPONSIBILITY OR ASSUME
              LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR A THIRD PARTY
              CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH
              OUR SERVICES. YOU UNDERSTAND AND AGREE THAT YOU MAY BE EXPOSED TO
              CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE
              INAPPROPRIATE, NONE OF WHICH WE OR OUR AFFILIATES WILL BE
              RESPONSIBLE FOR.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Linked will try to make the Services available
              to you, but we do not make any promises regarding quality and will
              not be liable for any content which is not ours.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">15. Limitation of Liability</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              NOTHING IN THESE TERMS SHALL EXCLUDE OR LIMIT OUR LIABILITY FOR
              LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE
              LAW. THIS INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED
              BY OUR NEGLIGENCE OR THE NEGLIGENCE OF OUR EMPLOYEES, AGENTS OR
              SUBCONTRACTORS AND FOR FRAUD OR FRAUDULENT MISREPRESENTATION.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              SUBJECT TO THE PARAGRAPH ABOVE, WE SHALL NOT BE LIABLE TO YOU FOR:
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              (I) ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY);
              <br />
              (II) ANY LOSS OF GOODWILL;
              <br />
              (III) ANY LOSS OF OPPORTUNITY;
              <br />
              (IV) ANY LOSS OF DATA SUFFERED BY YOU; OR
              <br />
              (V) ANY INDIRECT OR CONSEQUENTIAL LOSSES WHICH MAY BE INCURRED BY
              YOU. ANY OTHER LOSS WILL BE LIMITED TO THE AMOUNT PAID BY YOU TO
              LINKED WITHIN THE LAST 12 MONTHS.
              <br />
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU AS A RESULT OF:
            </p>
            <div style={{ fontFamily: "linkedmedium" }} className="ml-5 mb-5">
              <p className="mb-3">
                ⦁ ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR
                EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP
                OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE
                ADVERTISING APPEARS ON THE SERVICE;
              </p>
              <p className="mb-3">
                ⦁ ANY CHANGES WHICH WE MAY MAKE TO THE SERVICES, OR FOR ANY
                PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE
                SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
              </p>
              <p className="mb-3">
                ⦁ THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE ANY
                CONTENT AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED
                BY OR THROUGH YOUR USE OF THE SERVICES;
              </p>
              <p className="mb-3">
                ⦁ YOUR FAILURE TO PROVIDE US WITH ACCURATE ACCOUNT INFORMATION;
                OR
              </p>
              <p className="mb-3">
                ⦁ YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE
                AND CONFIDENTIAL.
              </p>
            </div>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              IF DEFECTIVE DIGITAL CONTENT THAT WE HAVE SUPPLIED DAMAGES A
              DEVICE OR DIGITAL CONTENT BELONGING TO YOU AND THIS IS CAUSED BY
              OUR FAILURE TO USE REASONABLE CARE AND SKILL, WE WILL EITHER
              REPAIR THE DAMAGE OR PAY YOU COMPENSATION. HOWEVER, WE WILL NOT BE
              LIABLE FOR DAMAGE THAT YOU COULD HAVE AVOIDED BY FOLLOWING OUR
              ADVICE TO APPLY AN UPDATE OFFERED TO YOU FREE OF CHARGE OR FOR
              DAMAGE THAT WAS CAUSED BY YOU FAILING TO CORRECTLY FOLLOW
              INSTALLATION INSTRUCTIONS OR TO HAVE IN PLACE THE MINIMUM SYSTEM
              REQUIREMENTS ADVISED BY US.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              THESE LIMITATIONS ON OUR LIABILITY TO YOU SHALL APPLY WHETHER OR
              NOT WE HAVE BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE
              POSSIBILITY OF ANY SUCH LOSSES ARISING.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
              ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICES,
              INCLUDING, BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER,
              COPYRIGHT OWNER OR OTHER USER, IS DIRECTLY BETWEEN YOU AND SUCH
              THIRD PARTY, AND YOU IRREVOCABLY RELEASE US AND OUR AFFILIATES
              FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND
              CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
              ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> We limit our liability for anything you do,
              instances where you cannot access the Services, things others do,
              and any issues resulting from unauthorized use of our Services.
              Where we are liable to you and, if you have suffered loss, we
              limit our liability to the amount paid to us within 12 months of
              the the incident.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">
              16. Dispute Resolution and Arbitration
            </h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              If you have a concern, let’s talk. Go ahead and{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                contact us
              </a>{" "}
              first, and we’ll do our best to resolve the issue.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Some of our Services may have additional terms that contain
              dispute resolution provisions unique to that Service or your
              residency.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              If you are using the Services on behalf of a business (rather that
              for your personal use), you and JustLinked Technologies Limited
              agree that to the extent permitted by law, all claims and disputes
              between us arising out of or relating to these Terms or the use of
              the Services will be finally settled through binding arbitration
              under the ADR - NICN Arbitration Rules, which are incorporated by
              reference into this clause. There will be one arbitrator (to be
              appointed by the ADR), the arbitration will take place in Nigeria
              and the arbitration will be conducted in English. If you do not
              wish to agree to this clause, you must not use the Services.
            </p>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              <b>In summary:</b> Get in touch with us if you have a complaint.
              Disputes with business users will be settled through arbitration
              in Nigeria.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">17. Exclusive Venue</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              To the extent that these Terms allow you or Linked to initiate
              litigation in a court, both you and Linked agree that all claims
              and disputes (whether contractual or otherwise) arising out of or
              relating to the Terms or the use of the Services will be litigated
              exclusively in the courts of Nigeria, unless this is prohibited by
              the laws of the country where you reside. You and Linked consent
              to the exclusive jurisdiction of those courts.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">18. Choice of Law</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              The laws of Nigeria govern these Terms, and any claims and
              disputes (whether contract, tort, or otherwise) arising out of or
              relating to these Terms or their subject matter. The courts in
              some countries may not apply the laws of Nigeria to some disputes
              related to these Terms. If you reside in one of those countries,
              the laws of your home country may apply to those disputes.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">19. Severability</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              If any provision of these Terms is found unenforceable, then that
              provision will be removed from these Terms and not affect the
              validity and enforceability of any remaining provisions.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">20. Final Terms</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              These Terms, including the additional terms referenced in Section
              3, make up the entire agreement between you and Linked, and
              supersede any prior agreements. These Terms do not create or
              confer any third-party beneficiary rights. If we do not enforce a
              provision in these Terms, it will not be considered a waiver of
              our rights to enforce these Terms. We reserve the right to
              transfer our rights under these Terms and provide the Services
              using another entity, provided that entity upholds these Terms.
              You may not transfer any of your rights or obligations under these
              Terms without our consent. We reserve all rights not expressly
              granted to you. Where we have provided summary sections in these
              Terms, these summaries have been included for your convenience
              only, and you should read these Terms in full to understand your
              legal rights and obligations.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div>
            <h1 className="text-xl mb-3">21. Contact Us</h1>
            <p style={{ fontFamily: "linkedmedium" }} className="mb-5">
              Linked welcomes comments, questions, concerns or suggestions.
              Please contact us by visiting{" "}
              <a
                className="text-sky-700 underline"
                href="https://justlinked.ng/contact_us"
              >
                our official website
              </a>
              .
            </p>
          </div>
        </div>

        <div className="mt-10">
          <p className="mt-5 text-lg">
            JustLinked Technologies Ltd. is located at 7 Polytex Street, Peace
            Estate, Ekorinim, Calabar, Calabar-Municipal, Cross River State.
            Nigeria.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
