import React from "react";
import VerifyImage from "../../images/verify.png";

const VerifyProfile = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="block items-center self-center">
        <img className="object-contain w-56 h-56" src={VerifyImage} />
        <h1>Verify your profile</h1>
      </div>
    </div>
  );
};

export default VerifyProfile;
