import React from 'react'
import { Link } from 'react-router-dom'
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { RiTwitterXFill } from "react-icons/ri";

const Footer = () => {
    return (

        <footer class="flex flex-col space-y-10 justify-center bg-black">

            <nav class="flex justify-center flex-wrap gap-6 text-white font-medium mt-16">
                <a class="hover:text-gray-900" href="/">Home</a>
                {/* <a class="hover:text-gray-900" href="https://justlinked.ng/privacy">Privacy</a> */}
                <Link to='/Privacy'>Privacy</Link>
                {/* <a class="hover:text-gray-900" href="https://justlinked.ng/terms">Terms</a> */}
                <Link to='/Terms'>Terms</Link>
                {/* <a class="hover:text-gray-900" href="https://justlinked.ng/faq">FAQ</a> */}
                <Link to='/FAQ'>FAQ</Link>
                {/* <a class="hover:text-gray-900" href="https://justlinked.ng/contact_us">Contact</a> */}
                <Link to='/ContactUs'>Contact Us</Link>
            </nav>

            <div class="flex justify-center space-x-5 items-center ">
                <a href="https://www.instagram.com/linkedapp_official" target="_blank" rel="noopener noreferrer">
                    <BsInstagram className='text-white'/>
                </a>
                <a href="https://twitter.com/justgotlinked" target="_blank" rel="noopener noreferrer">
                    <RiTwitterXFill className='text-white'/>
                </a>
            </div>
            <p class="text-center text-white font-medium">&copy; {new Date().getFullYear().toString()} JustLinked Technologies<br/>All rights reservered</p>
            <div className='mb-36'/>
        </footer>
    )
}

export default Footer