import React, { useState } from "react";
import { Helmet } from "react-helmet-async";

const Faq = () => {
  const DataForFaq = [
    {
      title: "What is Linked?",
      answer:
        "Linked is a revolutionary mobile application designed to foster connections and networking among students in an intuitive and fun way. With a simple swipe, students can find like-minded peers, potential study buddies and more",
    },
    {
      title: "Why Linked?",
      answer:
        "Linked was created to solve the problem of limited connection amongst students in various universities. It provides a centralised mobile solution for universities, connecting students and alumni seamlessly. It tackles fragmented communication by fostering collaboration and enhancing the overall university social experience.",
    },
    {
      title: "Who can use Linked?",
      answer: "While Linked was created primarily for university students, our platform is open to all individuals above the age of 18",
    },
    {
      title: "Where can I download Linked?",
      answer:
        "You can download the Linked app in the Apple App Store and in the Google Play Store.",
    },
    {
      title: "Do I have to pay to use Linked?",
      answer: "No you don't have to pay to use our services. Linked is free and will always be free.",
    },
    {
      title: "How do i verify my Linked Account?",
      answer:
        "To get verified on Linked, you'll have to add a phone number and upload a copy of any document that validates your identity as a student in the department and school you stated on your profile.",
    },
  ];

  return (
    <>
      {/* Set up metadata using Helmet */}
      <Helmet>
        <title>{"Linked ~ FAQS"}</title>
        <meta
          name="description"
          content={`frequently asked questions on linked!`}
        />
      </Helmet>
      <header class="bg-[#FFC629] py-20 h-80 items-center justify-center">
        <div class="container mx-auto text-black text-center  p-10">
          <h1 class="text-4xl font-semibold">Frequently Asked Questions</h1>
        </div>
      </header>
      <section className="relative z-20 overflow-hidden bg-[#000] pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[520px] text-center lg:mb-20 text-white">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  FAQ
                </span>
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[25px]">
                  Any Questions? Look Here
                </h2>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-1/2">
              {DataForFaq.map((faq, index) => {
                return <AccordionItem header={faq?.title} text={faq?.answer} />;
              })}
            </div>
          </div>
        </div>

        <div class="absolute bottom-0 right-0 z-[-1]">
          <svg
            width="1440"
            height="886"
            viewBox="0 0 1440 886"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="1308.65"
                y1="1142.58"
                x2="602.827"
                y2="-418.681"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3056D3" stop-opacity="0.36" />
                <stop offset="1" stop-color="#F5F2FD" stop-opacity="0" />
                <stop offset="1" stop-color="#F5F2FD" stop-opacity="0.096144" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>
    </>
  );
};

export default Faq;

//for a single item
const AccordionItem = ({ header, text }) => {
  const [active, setActive] = useState(false);

  const handleToggle = (event) => {
    // event.preventDefault();
    setActive(!active);
  };
  return (
    <div className="single-faq mb-8 w-full rounded-lg bg-[#262626] p-4 sm:p-8 lg:px-6 xl:px-8">
      <button
        className={`faq-btn flex w-full text-left`}
        onClick={() => handleToggle()}
      >
        <div className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-primary bg-opacity-5 text-primary">
          <svg
            className={`duration-200 ease-in-out fill-white stroke-primary  ${
              active ? "rotate-180" : ""
            }`}
            width="17"
            height="10"
            viewBox="0 0 17 10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
              fill=""
              stroke=""
            />
          </svg>
        </div>

        <div className="w-full">
          <h4 className="text-lg font-semibold text-white">{header}</h4>
        </div>
      </button>

      <div
        className={`pl-[62px] duration-200 ease-in-out ${
          active ? "block" : "hidden"
        }`}
      >
        <p
          style={{ fontFamily: "linkedlight" }}
          className="py-3 text-base leading-relaxed text-body-color text-white"
        >
          {text}
        </p>
      </div>
    </div>
  );
};
