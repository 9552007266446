import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import '../../index.css'


const Market = () => {

  const { postId, authorId  } = useParams();

  useEffect(()=>{
    try{
        window.location.href = `justlinked://market/${postId}/${authorId}`
    }catch{
        alert('Go donwload')
    }
  },[])

  return (
    <>
      <div className="h-screen bg-black flex items-center justify-center">
      <span class="loader"></span>
      </div>
    </>
  );
};

export default Market;
