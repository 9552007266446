import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div class="min-h-screen">
            <header class="bg-[#FFC629] py-10 h-auto items-center justify-center">
                <div class="container mx-auto text-black text-center p-10">
                    <h1 className="text-5xl font-semibold mb-5">JustLinked Technologies Limited</h1>
                    <h1 className='text-4xl font-semibold mb-5'>Privacy Policy</h1>
                    <h1 className='text-2xl font-semibold'>Effective: December 1, 2023</h1>
                </div>
            </header>

            <div className='p-10 text-white bg-black'>
                <div style={{ fontFamily: "linkedmedium" }} className='mt-5'>
                    <p>
                        JustLinked Technologies Ltd. is a technology company. Our products and services - that link to this Privacy Policy - provide fast, fun and personalised ways to express yourself, live in the moment, learn about the world and have fun together!
                        When you use these services, you’ll share some information with us.<br/><br/>So we want to be upfront about the information we collect, how we use it, how it's shared and the controls you have.
                        We've done our best to write this Privacy Policy in a way that's easy to understand for all our users and free of difficult language and legal phrases. Of course, you can always reach out to us for more clarity.
                    </p>
                </div>

                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>1.{' '}Information We Collect</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>There are three basic categories of information we collect:</p>
                    </div>
                    <div style={{ fontFamily: "linkedmedium" }} className='ml-5 mt-3'>
                        <ul>
                            <li>⦁	Information you provide.</li><br/>
                            <li>⦁	Information we get when you use our services.</li><br/>
                            <li>⦁	Information we get from third parties.</li>
                        </ul>
                    </div>
                    <p style={{ fontFamily: 'linkedmedium'}} className='mt-3'>Here’s a little more detail on each of these categories.</p>
                </div>

                <div className='mt-5'>
                    <div>
                        <h2 className='text-lg mb-3 underline'>Information You Provide:</h2>
                        <p style={{ fontFamily: 'linkedmedium'}}>
                           When you interact with our services, we collect information that you provide to us. For example, our services require you to set up an account, so we'll need to collect a few important details about you, such as your name, username, display pictures, password, email address, phone number, date of birth, university and department.<br/><br/>
                           We may also ask you to provide us with some additional information that will be publicly visible on our services, such as your year of study, tribe (ethnic group) and even your zodiac sign. And if you're looking to fund your Linked wallet, for gifting or subscriptions, we may ask for your payment information, like a debit or credit card number and associated account information.<br/><br/>
                           Of course, you'll also provide us with whatever information you send through our services, such as Profile Videos, Chats, Hypes, Public Profile information, Memories and more. Keep in mind that the users who view your Pofile Videos, Chats and any other content can always save that content or copy it outside the app. So, the same common sense that applies to the internet at large applies to our services as well: don't send messages or share content that you wouldn't want someone to save or share.<br/><br/>
                           Also, when you contact Support or communicate with us in any other way, we'll collect whatever information you volunteer or that we need to resolve your question.
                        </p>
                    </div>
                </div>

                <div className='mt-5'>
                    <div>
                        <h2 className='text-lg mb-3 underline'>Information We Get When You Use Our Services:</h2>
                        <p style={{ fontFamily: 'linkedmedium'}}>
                            When you use our services, we collect information about which of those services you’ve used and how you’ve used them. We might know, for instance, that you watched a particular Profile Video, viewed a Profile, saw a specific ad for a certain period of time, explored the map, and used certain filters. Here’s a fuller explanation of the types of information we collect when you use our services:<br/><br/>
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Usage Information: </b>We collect information about your activity through our services. For example, we may collect information about how you interact with our services;<br/>such as which Filters you apply, Profiles you view, Links you create, Links you save, Memories you view, or which search queries you submit.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Content Information: </b>When you use our services, you may engage with the camera or creative tools, and create and share various types of content. We collect information about content you create or provide on our services, and your engagement with the camera and creative tools. For certain content, this may include information based on the content of the image, video and audio. For example, if you post a Memory of you playing football or even wearing a jersey, we may show you other Memories related to football. We may also collect other information about the content, including metadata - which is information about the content itself like the date and time it was posted and who viewed it.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Device Information: </b>We collect information from and about the devices you use. For example, we may collect:
                        </p>
                        <div style={{ fontFamily: "linkedmedium" }} className='ml-5 mt-3'>
                            <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            ⦁	Information about your hardware and software, such as the hardware model, operating system version, device memory, advertising identifiers, unique application identifiers, apps installed, unique device identifiers, device usage data, browser type, keyboards installed, language, battery level and time zone;<br/><br/>
                            ⦁	Information from device sensors, such as accelerometers, gyroscopes, compasses, microphones, and whether you have headphones connected; and<br/><br/>
                            ⦁	Information about your wireless and mobile network connections, such as mobile phone number, service provider, IP address and signal strength.
                            </p>
                        </div>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                        	<b>Device Phonebook:</b> Since our services are all about creating links and communicating with friends, we may - with your permission - collect information from your device's phonebook, such as your contacts and related information.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Camera, Photos and Audio:</b>  Many of our services require us to collect images and other information from your device's camera, photos and microphone. For example, you won’t be able to post memories or upload photos from your camera roll unless we can access your camera or photos.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Location Information:</b>  When you use our services, we may collect information about your location. With your permission, we may also collect information about your precise location that includes methods such as GPS signals.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Information Collected by Cookies and Other Technologies:</b>  Like most online services and mobile applications, we may use cookies and other technologies such as web beacons, web storage and unique advertising identifiers to collect information about your activity, browser and device. We may also use these technologies to collect information when you interact with services we offer through one of our partners, such as advertising and commerce features. For example, we may use information collected on other websites to show you more relevant advertisements. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies through the settings on your browser or device. Keep in mind though, that removing or rejecting cookies could affect the availability and functionality of our services. To learn more about how we and our partners use cookies on our services and your choices, please check out our Cookie Policy.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Log Information:</b>  We may also collect log information when you use our website, such as: details about how you’ve used our services; device information, such as your web browser type and language; access times; pages viewed; IP address; identifiers associated with cookies or other technologies that may uniquely identify your device or browser; and pages you visited before or after navigating to our website.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                        	Other information, with your permission. There may be instances when you interact with our services that we will ask your permission to collect additional information. For example, if you want to participate in our Made For Me Panel or live in a jurisdiction where certain data is considered sensitive we will ask your permission and give you a heads-up on what data, including sensitive data, if any, we're collecting.
                        </p>
                    </div>
                </div>

                <div className='mt-5'>
                    <div>
                        <h2 className='text-lg mb-3 underline'>Information We Collect from Third Parties:</h2>
                        <p style={{ fontFamily: 'linkedmedium'}}>
                            We may collect information about you from other users, our affiliates, and third parties. Here are a few examples:
                        </p>
                        <div className='ml-5 mt-3'>
                            <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                                ⦁	We may obtain information from advertisers, app developers, publishers and other third-parties. We may use this information, among other ways, to help target or measure the performance of adverts.<br/><br/>
                                ⦁	If you provide us with your contact information, we may use that information and information obtained from third-parties to determine if we can communicate with you on other messaging platforms.<br/><br/>                  
                                ⦁	We may receive information from third-parties, including website publishers, social network providers, law enforcement and others about potential violators of our Terms of Service and Community Guidelines.
                            </p>
                        </div>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>2.{' '}How We Use Information</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>What do we do with the information we collect? The short answer is: provide you with an amazing set of products and services that we relentlessly improve. Here is a more detailed explanation on what exactly we do with the information we collect:</p>
                    </div>
                    <div style={{ fontFamily: "linkedmedium" }} className='ml-5 mt-3'>
                        <ul>
                            <li>⦁	Develop, improve, deliver, maintain and protect our products and services.</li><br/>
                            <li>⦁	Verify your identity and prevent fraud or other unauthorised or illegal activity.</li><br/>
                            <li>⦁	Enhance the safety and security of our products and services.</li><br/>
                            <li>⦁	Use information we’ve collected from cookies and other technology to enhance our services and your experience with them.</li><br/>
                            <li>⦁	Provide, personalise and improve our advertising services, ad targeting and ad measurement, including through the use of your content and precise location information (again, if you've given us permission to collect precise location information), both on and off our services. We may also store information about your use of third-party apps and websites on your device to do this. Learn more. See the Control Over Your Information section below for more information about our advertising practices and your choices.</li><br/>
                            <li>⦁	Add context to your experience, for example by tagging your Memories with searchable labels based on your location (of course, if you've given us permission to collect your precise location) and the content of your photo or video (for example, if there's a dog in your photo, it may be searchable in Memories by the term "dog").</li><br/>
                            <li>⦁	Personalise our services by, among other things, suggesting place recommendations on the Map, helping students create new Links, affiliate and third-party apps and services, inferring your interests based on your content or activity, or customising the content we show you, including ads.</li><br/>
                            <li>⦁	Develop and improve our algorithms and machine learning models, including for personalisation, advertising, and to prevent abuse or other Terms of Service violations.</li><br/>
                            <li>⦁	Conduct research to better understand user interests and trends, including the ways our services are used.</li><br/>
                            <li>⦁	Use information you provide to communicate with you on other messaging platforms.</li><br/>
                            <li>⦁	Enforce, investigate and report conduct violating our Terms of Service and other usage policies, respond to requests from law enforcement and comply with legal requirements.</li><br/>
                            <li>⦁	Send you communications, including by email, SMS or other messaging platforms, where permitted. For example, we may use email, SMS or other messaging platforms to respond to support inquiries or to share information about our products, services and promotional offers that we think may interest you.</li><br/>
                            <li>⦁	Monitor and analyse trends and usage.</li>
                        </ul>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>3.{' '}How We Share Information</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>Why do we share the information we collect? Most of the time, it's because you ask us to. Sometimes we share it because we need to. Here are the ways we may share information about you:</p>
                    </div>
                    <div className='mt-5'>
                        <div>
                            <h2 className='text-lg mb-3 underline'>With Potential Links:</h2>
                            <p style={{ fontFamily: 'linkedmedium'}}>
                                We may share the following information with other users on Linked:
                            </p>
                            <div className='ml-5 mt-3 w'>
                                <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                                    ⦁	Information about you, such as your username, name, university and department.<br/><br/>
                                    ⦁	Information about your device, such as the operating system and device type, to help you receive Chats and other content in the optimal viewing format for your device.<br/><br/>                  
                                    ⦁	If enabled, we will share your real-time precise location with your Links on Linked Map (but only for the duration you have chosen to share it).<br/><br/>
                                    ⦁	Content you post or send. How widely your content is shared depends on your personal settings and the type of service you are using. For example, a chat will be sent to a link as a direct message, Hypes will be seen amongst all other Hypes, while Memories can be view by any user allowed to view it.
                                </p>
                            </div>
                        </div>
                        <div>
                            <h2 className='text-lg mb-3 underline'>With Our Affiliates:</h2>
                            <p style={{ fontFamily: 'linkedmedium'}}>
                        	    We may share information with third parties in the following ways:
                            </p>
                            <div className='ml-5 mt-3'>
                                <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                                    ⦁	We may share information about you with service providers who perform services on our behalf, including to facilitate payments and measure and optimize the performance of ads and deliver more relevant ads, including on third-party websites and apps.<br/><br/>
                                    ⦁	We may share information about you with business partners that provide services and functionality on our services.<br/><br/>                  
                                    ⦁	We may share information about you, such as device and usage information, to help us and others prevent fraud.<br/><br/>
                                    ⦁	We may share information about you for legal, safety and security reasons. We may share information about you if we reasonably believe that disclosing the information is needed to:
                                </p>
                                <p style={{ fontFamily: 'linkedmedium'}} className='ml-5 mb-3'>
                                    ⦁	Comply with any valid legal processing, governmental request or applicable law, rule or regulation.<br/><br/>
                                    ⦁	Investigate, remedy or enforce potential Terms of Service and Community Guidelines violations.<br/><br/>                  
                                    ⦁	Protect the rights, property or safety of us, our users or others.<br/><br/>
                                    ⦁	Detect and resolve any fraud or security concerns.
                                </p>
                                <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                                    ⦁	We may share information about you as part of a merger or acquisition. If Linked gets involved in a merger, asset sale, financing, liquidation or bankruptcy or acquisition of all or some portion of our business to another company, we may share your information with that company before and after the transaction closes.<br/><br/>
                                    ⦁	Non-personal information. We may also share aggregated, non-personally identifiable or de-identified information with third parties that provide services to us or perform business purposes for us.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>4.{' '}Third-Party Content and Integrations</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>Our services may contain third-party content and integrations. Examples include third-party integrations in Anthems (Spotify) and subscriptions (Google or Apple). Through these integrations, you may be providing information to the third party as well as to Linked. We are not responsible for how those third parties collect or use your information. As always, we encourage you to review the privacy policies of every third-party service that you visit or use, including those third parties you interact with through our services.</p>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>5.{' '}How Long We Keep Your Information</h1>
                        <p style={{ fontFamily: "linkedmedium"}} className='mb-3'>
                            We store information for varying periods of time. For example, we store your basic account information — like your name, phone number and email address, and Saved Links until you ask us to delete them.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            Also, we store location information for different lengths of time based on how precise it is and which services you use. If location information is associated with a Memory or Profile Video, we'll retain that location as long as we store the Memory or Profile Video.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            If you ever decide to stop using Linked, you can just ask us to delete your account. We’ll also discard most of the information we’ve collected about you after you’ve been inactive for a while.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            Keep in mind that, while our systems are designed to carry out our deletion practices automatically, we cannot promise that deletion will occur within a specific time frame. There may be legal requirements to store your data, and we may need to suspend those deletion practices if we receive valid legal process asking us to preserve content, if we receive reports of abuse or other Terms of Service violations, or if your account, content created by you or content created with other users is flagged by others or our systems for abuse or other Terms of Service violations. Finally, we may also retain certain information in backup for a limited period of time or as required by law.
                        </p>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>6.{' '}Control Over Your Information</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>We want you to be in control of your information, so we provide you with the following tools:</p>
                    </div>
                    <div className='mt-5'>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                        	<b>Access, Correction, and Portability:</b> You can access and edit most of your basic account information right in our apps. Because your privacy is important to us, we will ask you to verify your identity or provide additional information before we let you access or update your personal information. We may also reject your request to access or update your personal information for a number of reasons, including, for example, if the request risks the privacy of other users, the integrity of Linked, or is unlawful. If you are verified, note that you will lose your verification badge and need to reverify your identity if you edit basic details like your name or age.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Revoking Permissions:</b> In most cases, if you let us use your information, you can simply revoke your permission by changing the settings in the app or on your device if your device offers those options. Of course, if you do that, certain services may lose full functionality. For promotional emails and messages sent by SMS or other messaging platforms, you may opt out by clicking on the unsubscribe link or similar mechanism as provided.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Deletion:</b>  While we hope you’ll stay with us for life, if for some reason you ever want to delete your account, just contact us. You can also delete some information in the app, like media you've uploaded to Memories or your Profile Video.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Advertising Preferences:</b>  We try to show you advertisements that we think will be relevant to your interests. If you would like to modify the information we and our advertising partners use to select these advertisements, you can do so in the app and through your device preferences.
                        </p>
                        <p style={{ fontFamily: 'linkedmedium'}} className='mb-3'>
                            <b>Communicating with Other Linked Users:</b>  It’s important to us that you stay in control over whom you communicate with. That's why we've built a number of tools in Settings that let you indicate, among other things, who you want to see your Memories and whether you'd like to block another user from contacting you again.
                        </p>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl mb-3'>7.{' '}Our Audience</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>
                            Our services are not intended for - and we don't direct them to - anyone under 18. And that's why we do not knowingly collect personal information from anyone under the age of 18. We collect information with the assumption that all users are adults by the laws of the Federal Republic of Nigeria.
                        </p>
                    </div>
                </div>


                <div className='mt-10'>
                    <div>
                        <h1 className='text-xl'>8.{' '}Changes to this Privacy Policy</h1>
                        <p style={{ fontFamily: "linkedmedium"}}>
                            We may change this Privacy Policy from time to time. But when we do, we’ll let you know one way or another. Sometimes, we’ll let you know by revising the date at the top of the Privacy Policy that’s available on our website and mobile application. Other times, we may provide you with additional notice (such as adding a statement to our websites’ home pages or providing you with an in-app notification).
                        </p>
                    </div>
                </div>


                <div className='mt-10'>
                    <p className='text-lg mt-5'>By using Linked, you acknowledge that you have read and understood this Privacy Policy and agree to the policies described herein.</p>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy