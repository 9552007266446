import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore";
import { Helmet } from "react-helmet-async";

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState(null);

  const { userId } = useParams();

  const fetchUser = async () => {
    const formedId = userId.toString();
    const userRef = doc(db, "Users", formedId);
    getDoc(userRef).then((query) => {
      if (query.exists) {
        setUserProfile(query.data());
      } else {
        Navigate({ to: "*" });
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {/* Set up metadata using Helmet */}
      <Helmet>
        <title>{`${userProfile?.username || "Linked"} - Profile`}</title>
        <meta name="description" content={`${userProfile?.bio}`} />
        <meta property="og:title" content={`${userProfile?.username}`} />
        <meta
          property="og:description"
          content={`Connect with ${userProfile?.username}`}
        />
        <meta property="og:image" content={`${userProfile?.photoUrl}`} />
      </Helmet>
      <div className="h-screen bg-black flex items-center justify-center">
        <div class="relative max-w-md mx-auto md:max-w-2xl min-w-0 break-words bg-[#262626] w-full mb-6 shadow-lg rounded-xl">
          <div class="px-6 top-100">
            <div class="flex flex-wrap justify-center">
              <div class="w-full flex justify-center">
                <div class="relative items-center self-center mt-4">
                  <img
                    src={userProfile?.photoUrl}
                    class="h-32 w-32 object-cover rounded-full"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-2">
              <h3 class="text-2xl text-white font-bold leading-normal mb-1">
                {userProfile?.username}
              </h3>
              <div class="text-xs mt-0 mb-2 text-slate-200 font-bold uppercase">
                <i class="fas fa-map-marker-alt mr-2 opacity-75 text-slate-200"></i>
                {userProfile?.institute}, {userProfile?.department}
              </div>
            </div>
            <div class="mt-6 py-6 border-t border-slate-200 text-center">
              <div class="flex flex-wrap justify-center">
                <div class="w-full px-4">
                  <p class="font-light leading-relaxed text-slate-200 mb-4">
                    {userProfile?.bio}
                  </p>
                  <Link to={`justlinked://user/${userId}`}>
                    <div className="bg-[#FFC629] p-2 rounded-full">
                      <p class="font-normal text-black hover:text-slate-400">
                        Open in app
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <footer class="relative pt-6 pb-2 mt-6">
                    <div class="container mx-auto px-4">
                        <div class="flex flex-wrap items-center md:justify-between justify-center">
                            <div class="w-full md:w-6/12 px-4 mx-auto text-center">
                                <div class="text-sm text-slate-500 font-semibold py-1">
                                    Created with ❤❤❤
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
    </>
  );
};

export default UserProfile;
