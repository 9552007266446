import React from 'react'

const ContactUs = () => {
    return (
        <>
            <header class="bg-[#FFC629] py-20 h-auto items-center justify-center">
                <div class="container mx-auto text-black text-center  p-10">
                    <h1 class="text-4xl font-semibold">Contact Us</h1>
                </div>
            </header>
            <section class="bg-black text-white">
                <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <p class="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">Got a technical issue? Want to send us a feedback? Need help with something? Let us know.</p>
                    <form action="#" class="space-y-8">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Your email</label>
                            <input type="email" id="email" class="shadow-sm bg-[#262626] text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="name@email.com" required />
                        </div>
                        <div>
                            <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 ">Subject</label>
                            <input type="text" id="subject" class="block p-3 w-full text-sm text-gray-900 bg-[#262626] rounded-lg  shadow-sm focus:ring-primary-500 focus:border-primary-500" placeholder="Let us know how we can help you" required />
                        </div>
                        <div class="sm:col-span-2">
                            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                            <textarea id="message" rows="6" class="block bg-[#262626] p-2.5 w-full text-sm text-gray-900  rounded-lg shadow-sm  focus:ring-primary-500 focus:border-primary-500 " placeholder="Leave a comment..."></textarea>
                        </div>
                        <button  class="py-3 px-5 text-sm font-medium text-center text-black rounded-lg bg-[#FFC629] sm:w-fit hover:bg-white focus:ring-4 focus:outline-none focus:ring-primary-300">Send message</button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default ContactUs