import React, { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import '../../index.css'

const Download = () => {
  const redirectToStore = () => {
    if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.danielonugu.linked';
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/app/linked/id6504049966";
    }else{
      window.location.href ='https://justlinked.ng'
    }
  };

  useEffect(() => {
    redirectToStore();
  }, []);
  return (
    <div className="h-screen bg-black flex items-center justify-center">
    <span class="loader"></span>
    </div>
  );
};

export default Download;
