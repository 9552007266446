import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { db } from "../../firebase";
import { getDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import { isAndroid, isIOS } from "react-device-detect";
import axios from "axios";
import "../../index.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Anonymous = () => {
  const [userProfile, setUserProfile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // Function to write to Firestore
  const writeToFirestore = async (messageText) => {
    try {
      const userRef = doc(
        db,
        "Users",
        userId,
        "anonymous",
        userId + Date.now().toString()
      );
      await setDoc(userRef, {
        text: text,
        timestamp: serverTimestamp(),
      });
      setIsLoading(false);
      alert("Message sent!");
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Function to send push notification using FCM REST API
  const sendPushNotification = async (token, title, body) => {
    const fcmUrl = "https://fcm.googleapis.com/fcm/send";
    const serverKey =
      "AAAAaUoxG1E:APA91bF9kvA8WbgcZZVOK-Qb0Usng8BklEeJwRBLpvSGsLv4Y0VzvdEzUL_gYx_AnD45KcoCyX1SbRBuIf_tQzkIISHym1-JYh4pDHcUVrMUPzW7jOEp6NNmQtk_-TikdT_ENZYS7R3r";

    const notificationPayload = {
      to: token,
      data: {
        type: "anonymous",
        extraId: null,
        userID: userId,
      },
      notification: {
        title: title,
        body: body,
      },
    };

    try {
      const response = await axios.post(fcmUrl, notificationPayload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `key=${serverKey}`,
        },
      });
    } catch (error) {
      return null;
    }
  };

  const { userId } = useParams();

  const handleSendMessage = async () => {
    setIsLoading(true);
    const userDocRef = doc(db, "Users", userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const fcmToken = userProfile?.pushId; // Assuming you have the FCM token stored in the user document

      await writeToFirestore(userId, text);
      await sendPushNotification(
        fcmToken,
        "New Message",
        "You have a new anonymous message."
      );

      setText(""); // Clear the text input
    } else {
      console.log("No such user!");
    }
  };

  const [text, setText] = useState("");

  const redirectToStore = () => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.danielonugu.linked";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/app/linked/id6504049966";
    } else {
      window.location.href = "https://justlinked.ng";
    }
  };

  const fetchUser = async () => {
    const formedId = userId.toString();
    const userRef = doc(db, "Users", formedId);
    getDoc(userRef).then((query) => {
      if (query.exists) {
        setUserProfile(query.data());
      } else {
        Navigate({ to: "*" });
      }
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const ProfileHeader = () => (
    <div className="relative bg-[#FFC629] rounded-t-2xl">
      <div className="flex items-center p-4">
        <img
          src={userProfile?.photoUrl}
          alt="DP"
          className="rounded-full h-12 w-12 mr-2"
        />
        <div>
          <div className="font-bold">@{userProfile?.username}</div>
          <div className="text-gray-600 text-sm">
            send me anonymous messages
          </div>
        </div>
      </div>
    </div>
  );

  const RoundedButton = ({ text, onClick }) => (
    <button
      style={{
        fontFamily: "linkedmedium",
      }}
      onClick={onClick}
      className="bg-[#fff] text-sm text-[#000] py-2 px-4 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
    >
      {isLoading ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        text
      )}
    </button>
  );

  return (
    <>
      {/* Set up metadata using Helmet */}
      <Helmet>
        <title>{`Send ${
          userProfile?.username || "this Linked user"
        } - Anonymous messages`}</title>
        <meta name="description" content={`${userProfile?.bio}`} />
        <meta
          property="og:description"
          content={`Connect with ${userProfile?.username}`}
        />
        <meta property="og:image" content={`${userProfile?.photoUrl}`} />
      </Helmet>
      <div className="h-screen bg-black flex items-center justify-center">
        <div className="max-w-lg mx-auto mt-8">
          <ProfileHeader
            profilePhoto="profile.jpg"
            username="johndoe"
            firstName="John"
          />
          <div className="bg-[#262626] rounded-b-2xl shadow-lg p-4">
            <textarea
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="w-full h-48 p-4 focus:outline-none  resize-none bg-[#262626] text-white"
              placeholder="Write your message..."
            />
          </div>
          <div className="mt-4 flex justify-center">
            <RoundedButton
              text="Send message"
              onClick={() => {
                if (text?.length < 1) {
                  alert("Your message is too short");
                } else {
                  handleSendMessage();
                }
              }}
            />
          </div>

          <div className="animated-button flex justify-center mt-36">
            <RoundedButton
              text="Get your own messages"
              onClick={redirectToStore}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Anonymous;
