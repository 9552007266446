import Home from "./component/Home/Home";
import { Routes, Route  } from "react-router-dom";
import PrivacyPolicy from "./component/Privacy/PrivacyPolicy";
import Terms from "./component/Privacy/Terms";
import UserProfile from "./component/Profile/UserProfile";
import NotFound from "./component/NotFound/NotFound";
import VerifyProfile from "./component/Verify/VerifyProfile";
import About from "./component/About/About";
import Faq from "./component/FAQ/Faq";
import Footer from "./component/Footer/Footer";
import ContactUs from "./component/Contact/ContactUs";
import Features from "./component/Features/Features";
import Download from "./component/Download/Download";
import ProductDetail from "./component/Market/ProductDetail";
import Moment from "./component/Moment/Moment";
import Gists from "./component/gists/Gists";
import Market from "./component/Market/Market";
import Anonymous from "./component/Anony/Anonymous";





function App() {
  return (
    <>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/moments/:momentId" element={<Moment />} />
          <Route path="/market/:postId/:authorId" element={<Market/>} />
          <Route path="/gists/:postId/:authorId" element={<Gists />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/faq" element={<Faq />} />
          {/* <Route path="/about_us" element={<About />} /> */}
          <Route path="/download" element={<Download />} />
          <Route path="/contact_us" element={<ContactUs/>} />
          <Route path="/verifyprofile/:userId" element={<VerifyProfile/>} />
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route path="/anonymous/:userId" element={<Anonymous />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
