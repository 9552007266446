// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAmF3qbJeM1xR0-CETLd_vmXxiBB9shMKI",
    authDomain: "linked-95bc6.firebaseapp.com",
    databaseURL: "https://linked-95bc6-default-rtdb.firebaseio.com",
    projectId: "linked-95bc6",
    storageBucket: "linked-95bc6.appspot.com",
    messagingSenderId: "452216298321",
    appId: "1:452216298321:web:119159c734d7dde9bf1170",
    measurementId: "G-XGNYZS03B2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)