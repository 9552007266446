import React, { useEffect, useState } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import '../../index.css'


const Gists = () => {

  const { postId, authorId  } = useParams();

  useEffect(()=>{
    window.location.href = `justlinked://gists/${postId}/${authorId}`;
  },[])

  return (
    <>
      <div className="h-screen bg-black flex items-center justify-center">
      <span class="loader"></span>
      </div>
    </>
  );
};

export default Gists;
