import React, { useEffect, useState } from "react";
import "../../index.css";
import Google from "../../images/play.png";
import Apple from "../../images/apple.png";
import QR from "../../images/qr.jpg";
import AppMockup1 from "../../images/mark.png";
import AppMockup2 from "../../images/fff.png";
import AppMockup3 from "../../images/dis.png";
import AppMockup4 from "../../images/vv.png";

const Home = () => {
  // Array of mockup images
  const mockupImages = [AppMockup4, AppMockup3, AppMockup2, AppMockup1];

  //word for the word rotator.
  const words = ["Discover", "Connect", "Meet"];

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedWord, setDisplayedWord] = useState("");

  useEffect(()=>{
    window.location.href = 'justlinked://';
  },[])

  useEffect(() => {
    const word = words[currentWordIndex];
    let currentIndex = 0;

    const interval = setInterval(() => {
      if (currentIndex <= word.length) {
        setDisplayedWord(word.substring(0, currentIndex));
        currentIndex++;
      } else {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setDisplayedWord("");
        currentIndex = 0;
      }
    }, 200); // Adjust typing speed (milliseconds per character, e.g., 200 ms per character)

    return () => clearInterval(interval);
  }, [currentWordIndex]);

  return (
  <>


    <div className="w-full bg-black min-h-screen flex flex-col items-center justify-center">
      <div className="max-w-screen-lg px-4 py-8 w-full flex flex-col items-center">

        <div 
        
        className="h-10 w-52 rounded-full bg-white flex items-center justify-center shadow-md shadow-[#eee] cursor-pointer">
          <a href="https://justlinked.ng/download">
           <p>Get Linked</p> 
          </a>
        </div>
        {/* Render mockup images */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-4">
          {mockupImages.map((image, index) => (
            <div key={index} className="w-full sm:w-auto">
              <img
                src={image}
                className="w-full h-auto"
                alt={`App Mockup ${index + 1}`}
              />
            </div>
          ))}
        </div>

        {/* Word rotator */}
        <div className="text-white mt-8 text-center">
          <h1 className="text-4xl font-bold">Unlock New Connections</h1>
          <h3 className="text-4xl font-bold animate-fadeInOut">
            {`.${displayedWord}.`}
          </h3>
        </div>

        {/* Store links */}
        <div className="flex items-center mt-4">
          <div className="cursor-pointer">
            <a
              href="https://play.google.com/store/apps/details?id=com.danielonugu.linked"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Google} alt="Google Play Store" />
            </a>
          </div>
          <div className="cursor-pointer ml-4">
          <a
              href="https://apps.apple.com/app/linked/id6504049966"
              target="_blank"
              rel="noopener noreferrer"
            >
            <img src={Apple} alt="Apple App Store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Home;



